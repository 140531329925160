<template>
	<div>
		<a-layout>
			<a-layout-sider class="layout-left-sider"
				style="background: #3D475D;overflow: hidden;z-index:10;flex: 0 0 80px; height: 100vh;box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);"
				width="80px">
				<div v-if="siteInfo != null" class="logo">
					<img :src="siteInfo.logo_round" />
				</div>
				<div style="flex: 1 1 0%; overflow: hidden auto;">

					<div class="menu-list">

						<div class="menu-item" v-for="(item, index) in menus" :key="index" @click="menuAct(index)"
							:class="{ on: getSelectKey.indexOf(item.key) > -1 }">
							<div class="text-center">
								<div class="iconfont ft24" :class="item.icon"></div>
								<!-- <img v-else style="width: 24px; height: 24px;" src="../assets/image/home_nav06@2x.png" /> -->
								<div class="mt8">{{ item.name }}</div>
							</div>
						</div>

					</div>
				</div>
				<!-- <div class="bottom">
					<div class="notice-icon" @click="toNotice()">
						 <a-badge :count="5" dot>
							<i class="iconfont iconnotice ft20 cl-w" ></i>
						 </a-badge>
					</div>
					<div class="text-center mt20">
						<a-popover  placement="right">
							<template  slot="content">
								<div class="manage-action-popover">
									<div class="item">
										 <i class="iconfont iconshop ft14 mr10"></i>
										 <span class="ft14">切换店铺</span>
									</div>
									
									<div class="item">
										 <i class="iconfont iconquit ft14 mr10"></i>
										 <span class="ft14">退出管理</span>
									</div>
								</div>
							</template>
<img class="manage-face" src="../assets/image/woyaoyou_head_defult@2x.png" />
</a-popover>
</div>
</div> -->
			</a-layout-sider>
			<a-layout-content style="height: 100vh; background: #F7F8FA;">
				<router-view></router-view>
			</a-layout-content>
		</a-layout>


	</div>
</template>

<script>
export default {

	data() {
		return {
			siteInfo: null,
			showCourse: false,
			menus: [
				{ name: '首页', icon: 'iconhome_nav01', key: 'homeIndex', link: '/index' },
				{ name: '会员', icon: 'iconhome_nav02', key: 'member', link: '/member' },
				// {name:'卡项管理',icon:'iconhome_nav_coursepakage',key:'project',link:'/project'},
				// {name:'优惠券',icon:'iconhome_nav04',key:'coupon',link:'/coupon'},
				{ name: '商城', icon: 'iconhome_navmall', key: 'mall', link: '/mall' },
				{ name: '轮播图', icon: 'iconhome_navalbum', key: 'look', link: '/look' },
				// {name:'会员分佣',icon:'iconmall_group_purchase',key:'partner',link:'/partner'},
				// {name:'店铺设置',icon:'iconhome_nav05',key:'setting',link:'/setting'},
				{ name: '店铺设置', icon: 'iconhome_nav05', key: 'setting', link: '/account' },
				{ name: '小程序', icon: ' iconhome_fun09_miniprogress', key: 'miniapp', link: '/miniapp' },
				// {name:'加入我们',icon:'icon-jiameng',key:'joinus',link:'/joinus'},
				// {name:'提现申请',icon:'icon-tixian',key:'bank',link:'/bank'},
			],
		}
	},
	created() {
		this.getSiteInfo();
		this.getArtificer();
	},
	methods: {
		getSiteInfo() {
			this.$http.papi('platform/siteInfo').then(res => {
				this.siteInfo = res.site;
			}).catch(res => {
				console.log(res);
			});
		},
		getArtificer() {
			this.$http.api('admin/showAppointmentSetting').then(res => {
				this.$store.commit('setArtificer', res.form.artificer == '' ? '技师' : res.form.artificer);
			}).catch(res => {

			})
		},
		menuAct(index) {
			let data = this.menus[index];
			if (data.key != this.getSelectKey) {
				this.$router.push(data.link);
			}
		},

		toNotice() {
			this.$router.push('/notice');
		},

	}
}
</script>

<style>
.layout-left-sider .ant-layout-sider-children {
	display: flex;
	flex-direction: column;
	height: 100%;
	line-height: 1;

}

.layout-left-sider .ant-layout-sider-children ::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.layout-left-sider .ant-layout-sider-children ::-webkit-scrollbar-thumb {
	background: rgb(0 0 0 / 15%);
	;
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 15%);
}

.layout-left-sider .ant-layout-sider-children ::-webkit-scrollbar-track {
	background: rgb(255 255 255 / 15%);
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 5px rgb(255 255 255 / 15%);
}

.layout-left-sider .logo {
	padding: 24px 16px;
	position: relative;
	display: flex;
	align-items: center;
}

.layout-left-sider .logo img {
	width: 48px;
	height: 48px;
}

.layout-left-sider .bottom {
	width: 100%;
	position: relative;
	padding: 24px 20px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.layout-left-sider .menu-list {
	padding: 0 6px;
	background: #3D475D;
}

.layout-left-sider .menu-item {
	width: 68px;
	height: 74px;
	background: #3D475D;
	border-radius: 8px;
	color: #9198A9;
	transition: all 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.layout-left-sider .menu-item.on {
	background: #4772FF;
	color: #FFFFFF;
}

.layout-left-sider .notice-icon {
	text-align: center;
	cursor: pointer;
}

.layout-left-sider .manage-face {
	width: 40px;
	height: 40px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
	border: 1px solid #FFFFFF;
	border-radius: 20px;
	cursor: pointer;
	background: #FFFFFF;
}

.add-action-popover {
	padding: 12px 16px;
}

.add-action-popover .item {
	width: 160px;
	height: 48px;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	align-items: center;
	color: #656A75;
	cursor: pointer;
	transition: background-color 0.3s;
}

.add-action-popover .item:hover {
	color: #567AF0;
	background: rgba(86, 122, 240, 0.06);
}

.manage-action-popover {
	padding: 12px 16px;
}

.manage-action-popover .item {
	width: 120px;
	height: 40px;
	border-radius: 4px;
	padding: 0 10px;
	display: flex;
	align-items: center;
	color: #656A75;
	cursor: pointer;
	transition: background-color 0.3s;
}

.manage-action-popover .item:hover {
	color: #567AF0;
	background: rgba(86, 122, 240, 0.06);
}
</style>